import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

import Footer from './Footer';

const PageBody = styled.div`
  background-color: #000;
`;
const PageContent = styled.div`
  color: white;
  padding: 50px 0;
`;

function Page({ children, style, contentStyle, footer }) {
  return (
    <PageBody style={style}>
      <PageContent style={contentStyle}>
        <Container>{children}</Container>
      </PageContent>
      {footer}
      <Footer basic />
    </PageBody>
  );
}

export default Page;
