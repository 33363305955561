import React, { useState } from 'react';

import { Modal, Message } from 'semantic-ui-react'
import { Link, useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function GlobalErrors(props) {
    const query = useQuery();
    const error = query.get('error');
    const errorDescription = query.get('error_description');
    const location = useLocation();
    const [open, setOpen] = useState(error === 'invalid_request' && errorDescription.includes('attributes required'));

    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
    >
        <Modal.Content>
            <Message color="red">
                <Message.Header>Sorry we were unable to retrieve your details</Message.Header>
                <p>Please choose another way to&nbsp;
                    <Link to={{
                        pathname: '/authenticate',
                        state: {
                            from: location
                        }
                    }}
                        onClick={() => setOpen(false)}>Join</Link>
                </p>
            </Message>
        </Modal.Content>
    </Modal>
}

export default GlobalErrors