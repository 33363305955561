import axios from 'axios';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_API_URL
});

export default {
  createAnalyticEvent: async (analyticEvent) => {
    try {
      await client.put('/analytic', analyticEvent, await authenticationService.getRequestOptions(null, false, true));
    }
    catch (ex) {
      console.warn('failed to record analytic events', ex, analyticEvent);
    }
  }
}