const dayjs = require('dayjs');
const _ = require('lodash');

const MAX_AD_PRICE = 999999999;

let dynamicPricing = {
  CAR: {
    priceRanges: [
      {
        priceType: 'AMOUNT',
        min: 0,
        max: 9999,
        prices: {
          GOOD: 29,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 10000,
        max: 24999,
        prices: {
          GOOD: 49,
          BETTER: 79,
          BEST: 99,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 25000,
        max: 49999,
        prices: {
          GOOD: 99,
          BETTER: 139,
          BEST: 199,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 50000,
        max: 74999,
        prices: {
          GOOD: 149,
          BETTER: 199,
          BEST: 299,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 75000,
        max: 149999,
        prices: {
          GOOD: 199,
          BETTER: 249,
          BEST: 399,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 150000,
        max: 249999,
        prices: {
          GOOD: 249,
          BETTER: 299,
          BEST: 449,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 250000,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 299,
          BETTER: 349,
          BEST: 499,
        },
      },
      {
        priceType: 'POA',
        text: 'Price On Application',
        min: -1,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 299,
          BETTER: 349,
          BEST: 499,
        },
      },
    ],
  },
  TRAILER: {
    priceRanges: [
      {
        priceType: 'AMOUNT',
        min: 0,
        max: 9999,
        prices: {
          GOOD: 29,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 10000,
        max: 24999,
        prices: {
          GOOD: 49,
          BETTER: 79,
          BEST: 99,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 25000,
        max: 49999,
        prices: {
          GOOD: 99,
          BETTER: 139,
          BEST: 199,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 50000,
        max: 74999,
        prices: {
          GOOD: 149,
          BETTER: 199,
          BEST: 299,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 75000,
        max: 149999,
        prices: {
          GOOD: 199,
          BETTER: 249,
          BEST: 399,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 150000,
        max: 249999,
        prices: {
          GOOD: 249,
          BETTER: 299,
          BEST: 449,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 250000,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 299,
          BETTER: 349,
          BEST: 499,
        },
      },
      {
        priceType: 'POA',
        text: 'Price On Application',
        min: -1,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 299,
          BETTER: 349,
          BEST: 499,
        },
      },
    ],
  },
  PART: {
    priceRanges: [
      {
        priceType: 'AMOUNT',
        min: 0,
        max: 4999,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 5000,
        max: 19999,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 20000,
        max: 49999,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 50000,
        max: 99999,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'AMOUNT',
        min: 99999,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
      {
        priceType: 'POA',
        text: 'Price On Application',
        min: -1,
        max: MAX_AD_PRICE,
        prices: {
          GOOD: 19,
          BETTER: 49,
          BEST: 79,
        },
      },
    ],
  },
};

function getDynamicPricing() {
  return _.cloneDeep(dynamicPricing);
}

function getAdCost(inventory, tier) {
  const price = inventory.adDetails?.price || 0;
  return getPriceRange(
    inventory.category,
    price,
    inventory.adDetails?.priceType,
    inventory.pricingType
  )?.prices[tier];
}

function getPriceRangeCost(priceRange, tier) {
  return priceRange ? priceRange.prices[tier] : 0;
}

function getPriceRange(category, price, priceType, pricingType) {
  if (_.isUndefined(price) && priceType !== 'POA') {
    return null;
  }

  const categoryPrices = getDynamicPricing()[category || 'CAR'].priceRanges;
  const priceTypeMatches = categoryPrices.filter(
    (x) => x.priceType === priceType
  );
  if (priceTypeMatches.length === 1) {
    return HandleStaticPricingExperiment(
      priceTypeMatches[0],
      category,
      pricingType
    );
  }
  return HandleStaticPricingExperiment(
    categoryPrices
      .filter((x) => !x.priceType || x.priceType === 'AMOUNT')
      .find((x) => x.min <= price && x.max >= price),
    category,
    pricingType
  );
}

function HandleStaticPricingExperiment(priceRange, category, pricingType) {
  if (priceRange && category === 'CAR' && pricingType === 'static') {
    priceRange.prices.GOOD = 49;
    priceRange.prices.BETTER = 79;
    priceRange.prices.BEST = 99;
  }
  return priceRange;
}

function GetPlanProperties(inventory) {
  const currentPlan = inventory.availablePlans?.find((x) => x.isSelected);
  const nextPlan = inventory.availablePlans?.find(
    (x) => x.order === currentPlan.order + 1
  );
  const isAuction = inventory.saleDetails?.saleType === 'AUCTION';
  const hasSlotsAvailable =
    currentPlan?.adSlotsAvailable > 0 && currentPlan?.active;
  const adIsActive =
    inventory.classicClassifiedId && inventory.salesState === 'AVAILABLE';

  let planStatus;

  if (isAuction || !currentPlan) {
    planStatus = 'PLAN_UNAVAILABLE';
  } else if (hasSlotsAvailable || adIsActive) {
    planStatus = 'PLAN_AVAILABLE';
  } else if (nextPlan) {
    planStatus = 'PLAN_UPGRADABLE';
  } else {
    planStatus = 'PLAN_CONSUMED';
  }

  return {
    planStatus,
    currentPlan,
    nextPlan,
    availablePlans: inventory.availablePlans,
  };
}

/**
 * @returns the price ranges relevant to an ad
 * - current:  The inferred current range for this ad - based on the current price of the ad
 * - confirmed:  The range the seller has explicitly confirmed, either from the price range screen or due to price increase
 * - purchased:  The range the seller has purchased.  Going above this will generally incur a charge
 * - pending: The range the seller will need to purchase to publish this ad
 * - active:  The price range the seller needs to be made aware of.  If it's higher than what they've purchased
 *            then they need to confirm it
 */
function getPriceRanges(inventory) {
  if (!inventory.adDetails?.priceRange) {
    return null;
  }

  const priceRanges = {
    current: getPriceRange(
      inventory.category,
      inventory.adDetails.price,
      inventory.adDetails.priceType,
      inventory.pricingType
    ),
    confirmed: getPriceRange(
      inventory.category,
      inventory.adDetails.priceRange.max,
      inventory.adDetails.priceRange.priceType,
      inventory.pricingType
    ),
    purchased: getPriceRange(
      inventory.category,
      inventory.purchasedPricing?.priceRange?.max,
      inventory.purchasedPricing?.priceRange?.priceType,
      inventory.pricingType
    ),
  };

  if (
    priceRanges.purchased &&
    priceRanges.current &&
    priceRanges.current.max > priceRanges.purchased.max &&
    priceRanges.confirmed &&
    priceRanges.confirmed.max >= priceRanges.current.max
  ) {
    priceRanges.pending = priceRanges.current;
  }

  const confirmedMax = priceRanges.confirmed?.max || 0;
  const currentMax = priceRanges.current?.max || 0;
  priceRanges.active =
    currentMax >= confirmedMax ? priceRanges.current : priceRanges.confirmed;

  return priceRanges;
}

function getAllProducts() {
  const allProducts = [
    {
      type: 'TIER',
      key: 'GOOD',
      chartColor: '#454d66',
      description: 'Standard Ad',
      tierValue: 1,
      maxImages: 10,
      inclusions: [],
      lifecycle: 'PERMANENT',
      prices: {
        CAR: 49,
        TRAILER: 49,
        PART: 19,
      },
    },
    {
      type: 'TIER',
      key: 'BETTER',
      chartColor: '#58b368',
      description: 'Premium Ad',
      tierValue: 2,
      maxImages: 20,
      inclusions: ['TOP_AD_15DAYS', 'HIGHLIGHT_15DAYS'],
      lifecycle: 'PERMANENT',
      prices: {
        CAR: 79,
        TRAILER: 79,
        PART: 49,
      },
    },
    {
      type: 'TIER',
      key: 'BEST',
      chartColor: '#efeeb4',
      description: 'Ultimate Ad',
      tierValue: 3,
      maxImages: 50,
      inclusions: ['TOP_AD_15DAYS', 'HIGHLIGHT_15DAYS', 'EMAIL_PROMOTION'],
      lifecycle: 'PERMANENT',
      prices: {
        CAR: 99,
        TRAILER: 99,
        PART: 79,
      },
    },
    {
      type: 'UPGRADE',
      key: 'CATEGORY_FEATURE_30',
      chartColor: '#26294a',
      description: 'Top Ad',
      lifecycle: 'EXPIRING',
      durationDays: 30,
      price: 65,
      isDeprecated: true,
    },
    {
      type: 'UPGRADE_GROUP',
      keyPrefix: 'TOP_AD',
      chartColor: '#26294a',
      description: 'Top Ad',
      lifecycle: 'EXPIRING',
      legacyKeys: ['CATEGORY_FEATURE_30'],
      variants: [
        {
          order: 1,
          keySuffix: '15DAYS',
          variantDescription: '15 days',
          durationDays: 15,
          price: 65,
        },
        {
          order: 2,
          keySuffix: '30DAYS',
          variantDescription: '30 days',
          durationDays: 30,
          price: 130,
        },
        {
          order: 3,
          keySuffix: '45DAYS',
          variantDescription: '45 days',
          durationDays: 45,
          price: 195,
        },
      ],
    },
    {
      type: 'UPGRADE',
      key: 'EXCLUSIVE',
      chartColor: '#055459',
      description: 'Hompage Feature',
      lifecycle: 'EXPIRING',
      durationDays: 14,
      price: 95,
      isDeprecated: true,
    },
    {
      type: 'UPGRADE_GROUP',
      keyPrefix: 'HOMEPAGE_FEATURE',
      chartColor: '#055459',
      description: 'Hompage Feature',
      lifecycle: 'EXPIRING',
      legacyKeys: ['EXCLUSIVE'],
      variants: [
        {
          order: 1,
          keySuffix: '15DAYS',
          variantDescription: '15 days',
          durationDays: 15,
          price: 95,
        },
        {
          order: 2,
          keySuffix: '30DAYS',
          variantDescription: '30 days',
          durationDays: 30,
          price: 190,
        },
        {
          order: 3,
          keySuffix: '45DAYS',
          variantDescription: '45 days',
          durationDays: 45,
          price: 285,
        },
      ],
    },
    {
      type: 'UPGRADE_GROUP',
      keyPrefix: 'FACEBOOK_PROMOTION',
      chartColor: '#077353',
      description: 'Facebook Post',
      lifecycle: 'TRANSIENT',
      unavailablePredicate: ({ inventory }) => {
        const previousPurchases = _.orderBy(
          inventory.purchases?.past?.filter((p) =>
            p.key.startsWith('FACEBOOK_PROMOTION')
          ),
          'purchasedOn',
          ['desc']
        );

        if (previousPurchases.length === 0) {
          return false;
        }

        const mostRecentPurchaseDays = dayjs().diff(
          previousPurchases[0].purchasedOn,
          'days'
        );

        return previousPurchases.length >= 2 || mostRecentPurchaseDays < 30;
      },
      variants: [
        {
          order: 1,
          keySuffix: 'STANDARD',
          variantDescription: 'Standard',
          price: 105,
        },
        {
          order: 2,
          keySuffix: 'BOOSTED',
          variantDescription: 'Boosted',
          price: 199,
        },
      ],
    },
    {
      type: 'UPGRADE',
      key: 'INSTAGRAM_PROMOTION',
      chartColor: '#14c285',
      description: 'Instagram Post',
      lifecycle: 'PERMANENT',
      price: 75,
    },
    {
      type: 'UPGRADE',
      key: 'EMAIL_PROMOTION',
      chartColor: '#abd96d',
      description: 'Email Newsletter',
      lifecycle: 'TRANSIENT',
      price: 95,
      unavailablePredicate: ({ inventory }) =>
        inventory.saleDetails?.saleType === 'AUCTION',
    },
    {
      type: 'UPGRADE',
      key: 'AUTO_ACTION_PROMOTION',
      chartColor: '#fcbf54',
      description: 'Auto Action Magazine',
      lifecycle: 'TRANSIENT',
      price: 99,
      unavailablePredicate: ({ inventory }) =>
        inventory.saleDetails?.saleType === 'AUCTION',
      isDeprecated: true,
    },
    {
      type: 'UPGRADE',
      key: 'STAND_OUT',
      chartColor: '#ee6c3b',
      description: 'Stand Out',
      lifecycle: 'PERMANENT',
      price: 35,
    },
    {
      type: 'UPGRADE_GROUP',
      keyPrefix: 'HIGHLIGHT',
      chartColor: '#055459',
      description: 'Highlight',
      lifecycle: 'EXPIRING',
      variants: [
        {
          order: 1,
          keySuffix: '15DAYS',
          variantDescription: '15 days',
          durationDays: 15,
          price: 35,
        },
        {
          order: 2,
          keySuffix: '30DAYS',
          variantDescription: '30 days',
          durationDays: 30,
          price: 70,
        },
        {
          order: 3,
          keySuffix: '45DAYS',
          variantDescription: '45 days',
          durationDays: 45,
          price: 105,
        },
      ],
    },
    {
      type: 'UPGRADE',
      key: 'BUMP_UP',
      chartColor: '#ee7c3b',
      description: 'Bump Up',
      lifecycle: 'TRANSIENT',
      price: 55,
      unavailablePredicate: ({ inventory }) => !inventory.classicClassifiedId,
    },
    {
      type: 'INFERRED',
      key: 'EMAIL_PROMOTION_OUR_FAVOURITES',
      description: 'Email Newsletter Our Favourites',
      lifecycle: 'TRANSIENT',
      price: 0,
      addedPredicate: ({ inventory }) => inventory.isAddToOurFavourites,
      unavailablePredicate: () => false,
    },
    {
      type: 'INFERRED',
      key: 'SECOND_CATEGORY',
      chartColor: '#ec0e47',
      description: 'Second Category',
      lifecycle: 'PERMANENT',
      price: 29,
      addedPredicate: ({ inventory }) => {
        return (
          inventory.secondarySubCategory &&
          inventory.secondarySubCategory.level1 &&
          inventory.secondarySubCategory.level1 !== 'NONE' &&
          inventory.secondarySubCategory.level2 &&
          inventory.secondarySubCategory.level2 !== 'NONE'
        );
      },
      unavailablePredicate: () => false,
      remove: (inventory) => {
        inventory.secondarySubCategory = {
          level1: 'NONE',
          level2: 'NONE',
        };
      },
    },
    {
      type: 'INFERRED',
      key: 'AUCTION',
      chartColor: 'red',
      description: 'Auction',
      lifecycle: 'PERMANENT',
      getPrice: (inventory, minimumTier, pastTier) => {
        if (pastTier) {
          return 0;
        }

        const { currentPlan } = GetPlanProperties(inventory);

        if (currentPlan) {
          return 99;
        }

        if (inventory.adDetails?.priceRange) {
          const price = inventory.adDetails?.priceRange?.max || 0;
          return getPriceRange(
            inventory.category,
            price,
            inventory.adDetails?.priceType,
            inventory.pricingType
          )?.prices[minimumTier.key];
        } else {
          return minimumTier.prices[inventory.category || 'CAR'];
        }
      },
      // For now lifecycle = PERMANENT ensures it can only be auctioned once
      unavailablePredicate: () => false,
      addedPredicate: ({ inventory }) => {
        return inventory.saleDetails?.saleType === 'AUCTION';
      },
      getDynamicProperties: () => ({}),
      remove: () => null,
    },
    {
      type: 'INFERRED',
      key: 'WAS_PRICING',
      chartColor: '#a02c5d',
      description: 'Show price drop',
      lifecycle: 'PERMANENT',
      price: 15,
      addedPredicate: ({ inventory }) => {
        return inventory.adDetails?.wasPricing?.enabled;
      },
      unavailablePredicate: ({ inventory, upgrade }) => {
        return (
          (inventory.adDetails &&
            ['POA', 'EXCLUDES_GOVERNMENT_CHARGES', 'DRIVE_AWAY'].includes(
              inventory.adDetails.priceType
            )) ||
          !upgrade.wasPrice
        );
      },
      getDynamicProperties: (inventory) => {
        const wasPricing =
          inventory.adDetails && inventory.adDetails.wasPricing;
        const eligibleWasPrice =
          wasPricing &&
          wasPricing.prices.find(
            (x) =>
              x.price > inventory.adDetails.price &&
              x.currencyCode === inventory.adDetails.currencyCode
          );
        return {
          wasPrice: eligibleWasPrice ? eligibleWasPrice.price : undefined,
        };
      },
      remove: (inventory) => {
        inventory.wasPricing.enabled = false;
      },
    },
    {
      type: 'INFERRED',
      key: 'URGENT_SALE',
      chartColor: '#a02c5d',
      description: 'Show urgent tag',
      lifecycle: 'PERMANENT',
      price: 15,
      addedPredicate: ({ inventory }) => {
        return (
          inventory.saleDetails?.isUrgent &&
          inventory.saleDetails?.isUrgentShown &&
          inventory.saleDetails?.saleType === 'OFFERED'
        );
      },
      unavailablePredicate: () => false,
      remove: (inventory) => {
        inventory.saleDetails.isUrgent = false;
        inventory.saleDetails.isUrgentShown = false;
      },
    },
    {
      type: 'INFERRED',
      key: 'DYNAMIC_PRICING',
      chartColor: '#b03c6d',
      description: 'Price Range Upgrade',
      lifecycle: 'TRANSIENT',
      /**
       * The price of the DYNAMIC_PRICING upgrade is the amount we'll present to sellers as the minimum they
       * will need to pay to get this ad published (either created or updated).
       * If an ad has not been published this price will include the cost of the minimum tier
       * If an ad has been published this price will be the amount they need to pay to upgrade their price range.
       * In this case it does not include the cost of any tier upgrades because the seller will be separately
       * notified of this, and that tier upgrade can be remove separately to the price range upgrade.
       */
      getPrice: (inventory, tier) => {
        const priceRanges = getPriceRanges(inventory);

        if (!priceRanges) {
          return 0;
        }

        const purchasedPriceRangeCost = getPriceRangeCost(
          priceRanges.purchased,
          tier.key
        );
        const currentPriceRangeCost = getPriceRangeCost(
          priceRanges.current,
          tier.key
        );

        return Math.max(currentPriceRangeCost - purchasedPriceRangeCost, 0);
      },
      /**
       * AVAILABLE implies the seller may need to confirm a price range upgrade
       * UNAVAILABLE implies there's nothing for the seller to be concerned about
       * ADDED implies the seller has already confirmed any required price range upgrade
       */
      unavailablePredicate: ({ inventory }) => {
        const priceRanges = getPriceRanges(inventory);

        return priceRanges?.active?.max === priceRanges?.purchased?.max;
      },
      addedPredicate: ({ inventory }) => {
        const priceRanges = getPriceRanges(inventory);

        return !!priceRanges?.pending;
      },
      /**
       * The message property here indicates to the UI what needs to be shown to the seller.
       * - CONFIRM:  The price of the ad exceeds what has been explicitly confirmed by the seller
       *              and so we need to get them to confirm it
       * - DOWNGRADE:  The price of the ad has fallen below what the seller has explicitly confirmed
       *                and there are some savings available to seller we should notifiy them of.
       *                The seller would have previously confirmed a higher price so we want to let
       *                them know they will be paying less than that now
       */
      getDynamicProperties: (inventory, tier) => {
        const priceRanges = getPriceRanges(inventory);

        if (!priceRanges) {
          return {};
        }

        let message;

        if (priceRanges.current && inventory.pricingType !== 'static') {
          if (priceRanges.current.max > priceRanges.confirmed.max) {
            message = 'CONFIRM';
          } else if (
            !inventory.adDetails.priceFocus &&
            inventory.adDetails.price &&
            priceRanges.current.max < priceRanges.confirmed.max &&
            priceRanges.confirmed.max > (priceRanges.purchased?.max || 0)
          ) {
            message = 'DOWNGRADE';
          }
        }

        const purchasedPriceRangeCost = getPriceRangeCost(
          priceRanges.purchased,
          tier.key
        );
        const activePriceRangeCost = getPriceRangeCost(
          priceRanges.active,
          tier.key
        );

        return {
          current: priceRanges.current,
          confirmed: priceRanges.confirmed,
          activePrice: Math.max(
            activePriceRangeCost - purchasedPriceRangeCost,
            0
          ),
          message,
        };
      },
      remove: () => null,
    },
    {
      type: 'INFERRED',
      key: 'SELLER_PLAN',
      chartColor: '#b02c5d',
      description: '', // Empty because we don't want this appearing anywhere
      lifecycle: 'TRANSIENT',
      price: 0,
      addedPredicate: ({ inventory, upgrade }) => {
        const adIsActive =
          inventory.classicClassifiedId && inventory.salesState === 'AVAILABLE';
        return upgrade.planStatus === 'PLAN_AVAILABLE' && !adIsActive;
      },
      unavailablePredicate: ({ upgrade }) => {
        return ['PLAN_UNAVAILABLE', 'PLAN_CONSUMED'].includes(
          upgrade.planStatus
        );
      },
      getDynamicProperties: (inventory) => GetPlanProperties(inventory),
      remove: () => null,
    },
  ];

  const flattenedGroups = allProducts
    .filter((product) => product.type === 'UPGRADE_GROUP')
    .flatMap((product) => {
      const variants = product.variants.map((variant) => ({
        ...product,
        ...variant,
        key: [product.keyPrefix, variant.keySuffix].filter((x) => x).join('_'),
        description: `${product.description} (${variant.variantDescription})`,
        type: 'UPGRADE',
        variants: null,
      }));
      const parent = {
        ...product,
        key: product.keyPrefix,
        type: 'UPGRADE',
        variants: variants,
      };
      variants.forEach((v) => (v.parent = parent));
      return [...variants, parent];
    });

  return [
    ...allProducts.filter((product) => product.type !== 'UPGRADE_GROUP'),
    ...flattenedGroups,
  ];
}

function sortDescendingBy(array, selector) {
  array.sort((a, b) => selector(b) - selector(a));
}

function computeTiers(allProducts, purchases) {
  const tiers = allProducts.filter((p) => p.type === 'TIER');
  const pendingTier = tiers.find((p) =>
    purchases.pending.map((pending) => pending.key).includes(p.key)
  );
  const pastTiers = tiers.filter((p) =>
    purchases.past.map((past) => past.key).includes(p.key)
  );
  const availableTiers = tiers.filter(
    (p) => p.status !== 'UNAVAILABLE_PURCHASED'
  );
  sortDescendingBy(pastTiers, (t) => t.tierValue);
  const pastTier = pastTiers.length > 0 && pastTiers[0];
  sortDescendingBy(availableTiers, (t) => t.tierValue);
  const minimumAvailableTier =
    availableTiers.length > 0 && availableTiers[availableTiers.length - 1];
  return {
    pendingTier,
    pastTier,
    minimumTier: minimumAvailableTier || pastTier,
  };
}

function setSelectedTierAndTierActions(
  inventory,
  pastTier,
  minimumTier,
  allProducts
) {
  const tiers = allProducts.filter((p) => p.type === 'TIER');
  sortDescendingBy(tiers, (t) => t.tierValue);
  const addedTier = tiers.find((t) => t.status === 'ADDED');
  const lastPurchasedTier = tiers.find(
    (t) => t.status === 'UNAVAILABLE_PURCHASED'
  );
  const selectedTier = addedTier ? addedTier : lastPurchasedTier;
  if (selectedTier) {
    selectedTier.isSelected = true;
  }

  tiers.forEach((tier) => {
    if (!selectedTier) {
      tier.action = 'ADD';
    } else if (
      tier.tierValue < selectedTier.tierValue &&
      (tier.status === 'INCLUDED' ||
        tier.status === 'AVAILABLE' ||
        tier.key === pastTier.key)
    ) {
      tier.action = 'DOWNGRADE';
    } else if (tier.tierValue > selectedTier.tierValue) {
      tier.action = 'UPGRADE';
    } else if (
      tier.tierValue === selectedTier.tierValue &&
      selectedTier.status === 'ADDED' &&
      pastTier
    ) {
      tier.action = 'REMOVE';
    } else {
      tier.action = 'NONE';
    }

    if (!pastTier || tier.tierValue > pastTier.tierValue) {
      if (inventory.adDetails?.priceRange) {
        const pastTierPrice =
          (pastTier && getAdCost(inventory, pastTier.key)) || 0;
        tier.price = getAdCost(inventory, tier.key) - pastTierPrice;
      } else {
        const pastTierPrice =
          (pastTier && pastTier.prices[inventory.category]) || 0;
        tier.price = tier.prices[inventory.category] - pastTierPrice;
      }
      const auctionProduct = allProducts.find((x) => x.key === 'AUCTION');
      if (auctionProduct.status === 'UNAVAILABLE_PURCHASED') {
        tier.price -= auctionProduct.getPrice(inventory, minimumTier, pastTier);
      } else if (tier.price <= 0) {
        tier.price = 10;
      }
    }
  });
}

const cartService = {
  removeProductFromInventory: (inventory, productKey) => {
    const productCallback = (product) => {
      if (product.type === 'INFERRED') {
        product.remove(inventory);
      }
    };
    inventory.purchases.pending = cartService.getPendingAfterRemoving(
      inventory.purchases,
      productKey,
      productCallback
    );
  },
  getPendingAfterRemoving: (purchases, productKey, productCallback) => {
    const allProducts = getAllProducts();
    const product = allProducts.find((p) => p.key === productKey);
    if (productCallback) {
      productCallback(product);
    }
    return purchases.pending.filter(
      (pending) =>
        pending.key !== productKey &&
        !product.variants?.find((v) => v.key === pending.key)
    );
  },
  getPendingAfterAdding: (purchases, productKey, isTransient) => {
    const allProducts = getAllProducts();
    let newPending = [...purchases.pending];

    const productToAdd = allProducts.find((p) => p.key === productKey);

    if (productToAdd.type === 'TIER') {
      // Make sure there's only ever one tier selected
      const tierKeys = allProducts
        .filter((p) => p.type === 'TIER')
        .map((p) => p.key);
      newPending = newPending.filter(
        (pending) => !tierKeys.includes(pending.key)
      );

      // Don't allow a tier to be readded if it's already been purchased
      const pastPurchase =
        purchases.past && purchases.past.find((p) => p.key === productKey);
      if (pastPurchase) {
        return newPending;
      }
    } else {
      newPending = newPending.filter(
        (pending) =>
          pending.key !== productKey &&
          !productToAdd.parent?.variants?.find((v) => v.key === pending.key)
      );
    }

    newPending.push({
      key: productToAdd.key,
      addedOn: new Date(),
      isTransient: isTransient,
    });

    return newPending;
  },
  getProductStatuses: (inventory) => {
    const { purchases } = inventory;
    const isAuction = inventory.saleDetails?.saleType === 'AUCTION';
    const scheduledEndingAt =
      isAuction && dayjs().add(inventory.saleDetails.durationDays, 'days');
    const allProducts = getAllProducts();

    const { pendingTier, pastTier, minimumTier } = computeTiers(
      allProducts,
      purchases
    );

    const isOnPlan = inventory.availablePlans?.find(
      (x) => x.isSelected
    )?.active;
    const isWithoutTier = isAuction || isOnPlan;

    function getStatusForTier(tier) {
      if (isWithoutTier) {
        return 'UNAVAILABLE';
      }
      if (pastTier && pastTier.tierValue >= tier.tierValue) {
        return 'UNAVAILABLE_PURCHASED';
      } else if (pendingTier && pendingTier.tierValue === tier.tierValue) {
        return 'ADDED';
      } else if (pendingTier && pendingTier.tierValue > tier.tierValue) {
        return 'INCLUDED';
      } else if (!pastTier || pastTier.tierValue < tier.tierValue) {
        return 'AVAILABLE';
      } else {
        return 'INVALID';
      }
    }

    function getStatusForUpgrade(inventory, purchases, upgrade) {
      const upgradePurchaseIsPending = purchases.pending.find(
        (pending) => pending.key === upgrade.key
      );
      const upgradeVariantPurchaseIsPending = purchases.pending.find(
        (pending) => upgrade.variants?.find((v) => v.key === pending.key)
      );
      const lastPurchase = getMostRecentPastPurchase(purchases, upgrade);

      if (
        upgrade.unavailablePredicate &&
        upgrade.unavailablePredicate({ inventory, upgrade, pastTier })
      ) {
        return 'UNAVAILABLE';
      } else if (
        pendingTier &&
        pendingTier.inclusions.includes(upgrade.key) &&
        (!pastTier || !pastTier.inclusions.includes(upgrade.key)) &&
        !isWithoutTier
      ) {
        if (upgradePurchaseIsPending) {
          return 'INCLUDED_ADDED';
        }
        return 'INCLUDED';
      } else if (
        upgrade.variants &&
        pendingTier &&
        pendingTier.inclusions.some((inclusionKey) =>
          upgrade.variants.find((variant) => variant.key === inclusionKey)
        ) &&
        (!pastTier ||
          !pastTier.inclusions.some((inclusionKey) =>
            upgrade.variants.find((variant) => variant.key === inclusionKey)
          )) &&
        !isWithoutTier
      ) {
        return 'VARIANT_INCLUDED';
      } else if (upgradePurchaseIsPending) {
        if (lastPurchase && upgrade.lifecycle.startsWith('PERMANENT')) {
          return 'INVALID';
        } else {
          return 'ADDED';
        }
      } else if (upgradeVariantPurchaseIsPending) {
        return 'VARIANT_ADDED';
      } else if (lastPurchase) {
        if (upgrade.lifecycle.startsWith('PERMANENT')) {
          return 'UNAVAILABLE_PURCHASED';
        } else {
          return 'AVAILABLE';
        }
      } else {
        return 'AVAILABLE';
      }
    }

    function getStatusForInferred(inventory, upgrade) {
      const lastPurchase = getMostRecentPastPurchase(
        inventory.purchases,
        upgrade
      );

      if (upgrade.unavailablePredicate({ inventory, upgrade, pastTier })) {
        return 'UNAVAILABLE';
      } else if (upgrade.lifecycle.startsWith('PERMANENT') && lastPurchase) {
        return 'UNAVAILABLE_PURCHASED';
      } else if (upgrade.addedPredicate({ inventory, pastTier, upgrade })) {
        return 'ADDED';
      } else {
        return 'AVAILABLE';
      }
    }

    function getUpgradeVariant(upgrade, productKey) {
      return (
        upgrade.variants?.find((v) => v.key === productKey) ||
        upgrade.parent?.variants.find((v) => v.key === productKey) ||
        allProducts.find((p) => p.key === productKey) ||
        upgrade // suspect we'll never get here
      );
    }

    function getPurchasesForUpgrade(purchases, upgrade) {
      return purchases.filter(
        (purchase) =>
          purchase.key &&
          (purchase.key === upgrade.key ||
            (upgrade.parent &&
              purchase.key.startsWith(upgrade.parent.keyPrefix)) ||
            (upgrade.keyPrefix && purchase.key.startsWith(upgrade.keyPrefix)) ||
            (upgrade.legacyKeys && upgrade.legacyKeys.includes(purchase.key)))
      );
    }

    function getCurrentExpiryForUpgrade(purchases, upgrade) {
      if (upgrade.lifecycle === 'EXPIRING') {
        const pastPurchases = getPurchasesForUpgrade(purchases.past, upgrade);
        sortDescendingBy(pastPurchases, (p) => dayjs(p.purchasedOn).valueOf());
        let totalSecondsRemaining = 0;
        pastPurchases.forEach((p) => {
          const expiresAt = dayjs(p.purchasedOn).add(
            getUpgradeVariant(upgrade, p.key).durationDays,
            'days'
          );
          const secondsRemaining = expiresAt.diff(dayjs(), 'seconds');
          if (secondsRemaining > 0) {
            totalSecondsRemaining += secondsRemaining;
          }
        });

        if (totalSecondsRemaining > 0) {
          return dayjs().add(totalSecondsRemaining, 'seconds');
        }

        const lastPurchase =
          pastPurchases.length > 0 ? pastPurchases[0] : undefined;
        return lastPurchase
          ? dayjs(lastPurchase.purchasedOn).add(
              getUpgradeVariant(upgrade, lastPurchase.key).durationDays,
              'days'
            )
          : null;
      }
    }

    function getPendingExpiryForUpgrade(purchases, upgrade) {
      const pendingPurchase = getPurchasesForUpgrade(
        purchases.pending,
        upgrade
      )[0];
      if (
        upgrade.lifecycle === 'EXPIRING' &&
        (pendingPurchase ||
          upgrade.status === 'INCLUDED' ||
          upgrade.status === 'VARIANT_INCLUDED')
      ) {
        let startDate = (upgrade.currentExpiry || dayjs()).isAfter(dayjs())
          ? upgrade.currentExpiry
          : dayjs();
        const includedVariant =
          upgrade.status === 'VARIANT_INCLUDED' &&
          upgrade.variants.find(
            (v) => v.status === 'INCLUDED' || v.status === 'INCLUDED_ADDED'
          );
        if (pendingPurchase) {
          startDate = startDate.add(
            getUpgradeVariant(upgrade, pendingPurchase.key).durationDays,
            'days'
          );
        }
        if (includedVariant) {
          startDate = startDate.add(
            getUpgradeVariant(upgrade, includedVariant.key).durationDays,
            'days'
          );
        }
        if (!pendingPurchase && !includedVariant) {
          startDate = startDate.add(
            getUpgradeVariant(upgrade, upgrade.key).durationDays,
            'days'
          );
        }

        return startDate;
      }
      return null;
    }

    function getMostRecentPastPurchase(purchases, product) {
      const pastPurchases = getPurchasesForUpgrade(purchases.past, product);
      sortDescendingBy(pastPurchases, (p) => dayjs(p.purchasedOn).valueOf());
      return pastPurchases.length > 0 ? pastPurchases[0] : undefined;
    }

    function getLastPurchasedOn(purchases, product) {
      const lastPurchase = getMostRecentPastPurchase(purchases, product);
      return lastPurchase ? dayjs(lastPurchase.purchasedOn) : null;
    }

    function getNextExpiresOn(product) {
      let expiresOn = product.currentExpiry;
      if (
        product.pendingExpiry &&
        dayjs(product.pendingExpiry).isAfter(expiresOn || dayjs())
      ) {
        expiresOn = product.pendingExpiry;
      }
      return expiresOn ? scheduledEndingAt || expiresOn : expiresOn;
    }

    function getIsActive(product) {
      if (
        product.lifecycle.startsWith('PERMANENT') ||
        product.lifecycle === 'TRANSIENT'
      ) {
        return (
          product.lastPurchasedOn &&
          dayjs(product.lastPurchasedOn).isBefore(dayjs())
        );
      } else if (product.lifecycle === 'EXPIRING') {
        return (
          product.currentExpiry && dayjs(product.currentExpiry).isAfter(dayjs())
        );
      }
      return false;
    }

    function hydrateProduct(product) {
      if (product.type === 'TIER') {
        product.status = getStatusForTier(product);
        product.isSelected = false;
      } else if (product.type === 'UPGRADE') {
        product.status = getStatusForUpgrade(inventory, purchases, product);
        product.currentExpiry = getCurrentExpiryForUpgrade(purchases, product);
        product.pendingExpiry = getPendingExpiryForUpgrade(purchases, product);
        product.nextExpiresOn = getNextExpiresOn(product);
        product.lastPurchasedOn = getLastPurchasedOn(purchases, product);
        product.isActive = getIsActive(product) || false;
      } else if (product.type === 'INFERRED') {
        if (product.getDynamicProperties) {
          const dynamicProperties = product.getDynamicProperties(
            inventory,
            minimumTier
          );
          for (let key in dynamicProperties) {
            product[key] = dynamicProperties[key];
          }
        }
        if (product.getPrice) {
          product.price = product.getPrice(inventory, minimumTier, pastTier);
        }
        product.status = getStatusForInferred(inventory, product);
      }
    }

    allProducts.forEach(hydrateProduct);

    setSelectedTierAndTierActions(
      inventory,
      pastTier,
      minimumTier,
      allProducts
    );

    return allProducts.reduce((ac, a) => ({ ...ac, [a.key]: a }), {});
  },
  getUpgradeStatuses: (inventory) => {
    const productStatuses = cartService.getProductStatuses(inventory);
    const allProducts = Object.values(productStatuses);
    const dashboardUpgradeStatuses = allProducts.filter(
      (p) => p.type === 'UPGRADE'
    );
    return dashboardUpgradeStatuses.reduce(
      (ac, a) => ({ ...ac, [a.key]: a }),
      {}
    );
  },
  getMinimumPrices: () => {
    const goodTier = getAllProducts().find((x) => x.key === 'GOOD');
    return goodTier.prices;
  },
  getAllProducts,
  getAllPriceRanges: (category, pricingType) =>
    getDynamicPricing()[category].priceRanges.map((range) =>
      HandleStaticPricingExperiment(range, category, pricingType)
    ),
  setupDynamicPricingForTesting: (pricing) => (dynamicPricing = pricing),
  MAX_AD_PRICE,
};

export default cartService;
