import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon, Loader, Modal, Table, Button } from "semantic-ui-react";
import AdSurveyResponseService from "../../Services/AdSurveyResponseService";
import Slider from "react-slick";
import RatingStars from "./RatingStars";

const Container = styled.div`
  background-color: #2b2b2b !important;
  padding: 40px;
  text-align: center;
  border-radius: 5px;
  color: white;
`;

const RatingsRow = styled.div`
  padding-bottom: 20px;
  overflow-x: auto;
  max-width: 1300px;
  margin: 0 auto 20px auto;

  @media (min-width: 768px) {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
`;

const Quote = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  justify-content: center;

  p {
    margin: 0;
    font-style: italic;
  }

  i {
    color: #ccc;
    margin: 0;
  }
`;

const AverageRatingContainer = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 768px) {
    float: left;
  }
`;

const TestimonialContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
  margin: 0 auto;
`;

const TestimonialDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  &,
  a {
    color: #ccc;
  }
  align-self: center;
  max-width: 250px;
  @media (min-width: 768px) {
    align-self: initial;
  }
`;

const TestimonalDetail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Testimonial({
  rating,
  message,
  name,
  location,
  dateAgo,
  adTitle,
  adUrl,
}) {
  return (
    <TestimonialContainer>
      <RatingStars rating={rating} size="small" />
      <Quote>
        <Icon name="quote left" size="small" />
        <p>{message}</p>
        <Icon name="quote right" size="small" />
      </Quote>
      <TestimonialDetails>
        <TestimonalDetail>{name}</TestimonalDetail>
        <TestimonalDetail>{dateAgo}</TestimonalDetail>
        <TestimonalDetail>
          <small>{location}</small>
        </TestimonalDetail>
        <small>
          <a href={adUrl} target="_blank" rel="noopener noreferrer">
            <Icon name="external" />
            &nbsp;
            <span style={{ textDecoration: "underline" }}>{adTitle}</span>
          </a>
        </small>
      </TestimonialDetails>
    </TestimonialContainer>
  );
}

const ReviewMessage = styled.p`
  margin-top: 10px;
`;

function Review({
  rating,
  message,
  name,
  location,
  dateAgo,
  date,
  adTitle,
  adUrl,
}) {
  return (
    <Table.Row>
      <Table.Cell textAlign="center">
        <RatingStars rating={rating} size="large" />
        <ReviewMessage>{message}</ReviewMessage>
      </Table.Cell>
      <Table.Cell singleLine>
        {name} - {dateAgo}
      </Table.Cell>
      <Table.Cell singleLine>
        {location} - {date}
      </Table.Cell>
      <Table.Cell singleLine>
        <a
          href={adUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          <span style={{ textDecoration: "underline" }}>{adTitle}</span>
          &nbsp;&nbsp;
          <Icon name="external" />
        </a>
      </Table.Cell>
    </Table.Row>
  );
}

function SellerRatingsList({ open, setOpen }) {
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const getNextPage = useCallback(async () => {
    setIsLoading(true);
    const page = await AdSurveyResponseService.getAdSurveyResponses(
      list.length
    );
    setList([...list, ...page]);
    setIsLoading(false);
  }, [list]);

  useEffect(() => {
    if (open && list.length === 0) {
      getNextPage();
    }
  }, [open, getNextPage, list.length]);

  return (
    <Modal
      basic
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="fullscreen"
    >
      <Modal.Content>
        <Button
          basic
          inverted
          floated="right"
          icon="close"
          onClick={() => setOpen(false)}
        />
        <Table inverted basic>
          {list.map((review) => (
            <Review key={review.id} {...review} />
          ))}
        </Table>
        <p style={{ textAlign: "center" }}>
          <Button
            basic
            inverted
            onClick={getNextPage}
            loading={isLoading}
            disabled={isLoading}
            style={{ textAlign: "center" }}
          >
            Load More
            <Icon name="caret right" />
          </Button>
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic inverted onClick={() => setOpen(false)}>
          <Icon name="close" /> Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const TestimonialsContainer = styled.div`
  padding: 0 50px;
  margin: 40px auto 0 auto;

  @media (min-width: 768px) {
    margin: 0 0 0 200px;
  }

  .slick-prev {
    height: 60px;
    &:before {
      content: "‹";
      font-size: 60px;
    }
  }
  .slick-next {
    height: 60px;
    :before {
      content: "›";
      font-size: 60px;
    }
  }
`;

function TestimonialsSlider({ adSurveyResponseSummary }) {
  const sliderSettings = {
    slidesToShow: 4,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TestimonialsContainer>
      <Slider {...sliderSettings}>
        {adSurveyResponseSummary.adSurveyResponses.map((data) => (
          <Testimonial key={data.id} {...data} />
        ))}
      </Slider>
    </TestimonialsContainer>
  );
}

function ShowReviewsButton({ children, onClick }) {
  return (
    <span
      style={{ textDecoration: "underline", cursor: "pointer" }}
      onClick={onClick}
    >
      {children}
    </span>
  );
}

function SellerRatingsWidget() {
  const [modalOpen, setModalOpen] = useState(false);
  const [adSurveyResponseSummary, setAdSurveyResponseSummary] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const adSurveyResponseSummary =
          await AdSurveyResponseService.getAdSurveyResponseSummary();
        setAdSurveyResponseSummary(adSurveyResponseSummary);
      } catch (ex) {
        console.error("failed to load seller reviews", ex);
      }
    })();
  }, []);

  return (
    <Container>
      <h2 style={{ marginBottom: "40px" }}>What do other sellers think?</h2>

      {adSurveyResponseSummary ? (
        <>
          <RatingsRow>
            <AverageRatingContainer>
              <div>
                <span style={{ fontSize: "32px", lineHeight: "32px" }}>
                  {adSurveyResponseSummary.averageRating.score}
                </span>{" "}
                / 5
              </div>
              <RatingStars
                rating={adSurveyResponseSummary.averageRating.score}
                size="big"
                color="red"
              />
              <div>
                <div>
                  Based on{" "}
                  <ShowReviewsButton onClick={() => setModalOpen(true)}>
                    {adSurveyResponseSummary.averageRating.count} reviews
                  </ShowReviewsButton>
                </div>
                <div>
                  within {adSurveyResponseSummary.averageRating.periodMonths}{" "}
                  months
                </div>
              </div>
            </AverageRatingContainer>
            <TestimonialsSlider
              adSurveyResponseSummary={adSurveyResponseSummary}
            />
          </RatingsRow>
          <ShowReviewsButton onClick={() => setModalOpen(true)}>
            See all reviews
          </ShowReviewsButton>
        </>
      ) : (
        <Loader active inline="centered">
          Loading reviews...
        </Loader>
      )}

      <SellerRatingsList open={modalOpen} setOpen={setModalOpen} />
    </Container>
  );
}

export default SellerRatingsWidget;
