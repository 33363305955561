import React from 'react';
import Rating from 'react-rating';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const RatingStarsContainer = styled.div`
  white-space: nowrap;
  i.icon {
    color: #ccc;
    margin: 0 2px !important;
  }
  &.color-red {
    i.icon {
      color: #ed1f24;
    }
  }
`;

export default function RatingStars({ rating, size, color }) {
  return (
    <RatingStarsContainer className={color ? 'color-' + color : ''}>
      <Rating
        initialRating={rating}
        emptySymbol={<Icon name="star outline" size={size} />}
        fullSymbol={<Icon name="star" size={size} />}
        readonly
      />
    </RatingStarsContainer>
  );
}
