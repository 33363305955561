import cartService from '../Services/CartService';
import authenticationService from '../Services/AuthenticationService';

const gtagTryEvent = async (eventName, eventPayload) => {
  await gtagTry('event', eventName, eventPayload);
};

const gtagTry = async (...params) => {
  if (await authenticationService.isAdmin()) {
    return;
  }

  try {
    window.gtag(...params);
  } catch (error) {
    console.error('gtag event failed', error);
  }
};

const analyticService = {
  sendSelectContentEvent: (category, price) => {
    gtagTryEvent('select_content', {
      content_type: category,
      items: [
        {
          id: category,
          name: category,
          list_name: 'Category Listing',
          brand: 'MY105',
          category: category,
          quantity: 1,
          price: price,
        },
      ],
    });
  },
  sendAddToCartEvent: (product, currentListing) => {
    gtagTryEvent('add_to_cart', {
      items: [
        {
          id: product.key,
          name: product.description,
          list_name: 'Purchases Pending',
          brand: 'MY105',
          category: currentListing.category,
          quantity: 1,
          price: product.price,
        },
      ],
    });
  },
  sendRemoveFromCartEvent: (product, currentListing) => {
    gtagTryEvent('remove_from_cart', {
      items: [
        {
          id: product.key,
          name: product.description,
          list_name: 'Purchases Pending',
          brand: 'MY105',
          category: currentListing.category,
          quantity: 1,
          price: product.price,
        },
      ],
    });
  },
  sendBeginCheckoutEvent: (purchases, category) => {
    const items = purchases.map((purchase) => {
      let price = purchase.price
        ? purchase.price
        : purchase.prices
        ? purchase.prices[category]
        : '';
      return {
        id: purchase.key,
        name: purchase.description ? purchase.description : '',
        list_name: 'Begin Checkout',
        brand: 'MY105',
        category: category,
        quantity: 1,
        price: price,
      };
    });

    gtagTryEvent('begin_checkout', {
      items: items,
    });
  },
  sendPurchaseEvent: (id, category, totalAmount, purchases, list_name) => {
    let itemArray = [];
    purchases.forEach((purchase) => {
      let price = purchase.price
        ? purchase.price
        : purchase.prices
        ? purchase.prices[category]
        : '';
      itemArray.push({
        id: purchase.key,
        list_name: list_name,
        brand: 'MY105',
        category: category,
        quantity: 1,
        price: price,
      });
    });

    gtagTryEvent('purchase', {
      transaction_id: id,
      value: totalAmount,
      currency: 'AUD',
      tax: 0,
      shipping: 0,
      items: itemArray,
    });
  },
  sendViewItemListEvent: () => {
    const minimumPrices = cartService.getMinimumPrices();
    gtagTryEvent('view_item_list', {
      items: [
        {
          id: 'CAR',
          name: 'CAR',
          list_name: 'Landing Page',
          brand: 'MY105',
          category: 'CAR',
          quantity: 1,
          price: minimumPrices.CAR,
        },
        {
          id: 'TRAILER',
          name: 'TRAILER',
          list_name: 'Landing Page',
          brand: 'MY105',
          category: 'TRAILER',
          quantity: 1,
          price: minimumPrices.TRAILER,
        },
        {
          id: 'PART',
          name: 'PART',
          list_name: 'Landing Page',
          brand: 'MY105',
          category: 'PART',
          quantity: 1,
          price: minimumPrices.PART,
        },
      ],
    });
  },
  sendCheckoutTrackingEvent: (purchases, category, step) => {
    const items = purchases.map((purchase) => {
      let price = purchase.price
        ? purchase.price
        : purchase.prices
        ? purchase.prices[category]
        : '';
      return {
        id: purchase.key,
        name: purchase.description ?? purchase.key,
        brand: 'MY105',
        category: category,
        quantity: 1,
        price: price ?? 0,
      };
    });

    gtagTryEvent('checkout_progress', {
      checkout_step: step,
      items: items,
    });
  },
};

export default analyticService;
