import React, { useEffect } from 'react';
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';

import asyncComponent from "./Components/Furniture/AsyncComponent"
import ScrollToTop from "./Components/Furniture/ScrollToTop"
import Header from "./Components/Furniture/Header"
import PrivateRoute from "./Components/Furniture/PrivateRoute"
import GlobalErrors from "./Components/Furniture/GlobalErrors"
import { ProvideAuth } from './Hooks/use-auth';
import authenticationService from "./Services/AuthenticationService";

// Making this one synchronous too otherwise the snapshotting causes a flash
import LandingPage from "./Pages/Landing/LandingPage";

const AsyncCreate = asyncComponent(() => import("./Pages/Create/CreatePage"));
const AsyncNotFound = asyncComponent(() => import("./Pages/Error/NotFoundPage"));
const AsyncAuthenticate = asyncComponent(() => import("./Pages/Authenticate/AuthenticatePage"));
const AsyncDashboard = asyncComponent(() => import("./Pages/Dashboard/DashboardPage"));
const AsyncAdmin = asyncComponent(() => import("./Pages/Admin/AdminPage"));
const AsyncAdminAnalytics = asyncComponent(() => import("./Pages/Admin/AdminAnalyticsPage"));
const AsyncCompleteRegistrationPage = asyncComponent(() => import("./Pages/Authenticate/CompleteRegistrationPage"));
const AsyncUnauthorizedErrorPage = asyncComponent(() => import("./Pages/Error/UnauthorizedErrorPage"));
const AsyncUpdateArea = asyncComponent(() => import("./Pages/Update/UpdateArea"));
const AsyncAdStatsPage = asyncComponent(() => import("./Pages/AdStats/AdStatsPage"));
const AsyncAdStatsUnsubscribePage = asyncComponent(() => import("./Pages/AdStats/UnsubscribePage"));

const PageViewTrackedRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (!await authenticationService.isAdmin()) {
        try {
          window.gtag('event', 'page_view', {
            page_location: window.location.href,
            page_path: location.pathname + location.search,
            page_title: 'My105 Sell'
          });
        }
        catch (error) {
          console.error('page view tracking failed', error);
        }
      }
    })()
  }, [location]);

  return (
    <>
      <Header />
      <ScrollToTop>
        <GlobalErrors />
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route
            path="/"
            exact
            component={LandingPage} />

          <PrivateRoute
            allowGuest={true}
            path="/create/:category/:tier"
            exact
            render={(routeProps) => <AsyncCreate {...routeProps} />} />

          <PrivateRoute
            path="/dashboard/:sellerId?"
            exact
            render={(routeProps) => <AsyncDashboard {...routeProps} />} />

          <PrivateRoute
            path="/admin"
            exact
            render={(routeProps) => <AsyncAdmin {...routeProps} />} />

          <PrivateRoute
            path="/admin-analytics"
            exact
            render={(routeProps) => <AsyncAdminAnalytics {...routeProps} />} />

          <Route
            path="/update/:id"
            render={(routeProps) => <AsyncUpdateArea {...routeProps} />} />

          <Route
            path="/adstats/unsubscribe"
            exact
            render={(routeProps) => <AsyncAdStatsUnsubscribePage {...routeProps} />} />

          <PrivateRoute
            path="/adstats/:id"
            exact
            render={(routeProps) => <AsyncAdStatsPage {...routeProps} />} />

          <Route
            path="/authenticate"
            exact
            component={AsyncAuthenticate} />

          <PrivateRoute
            path="/complete-registration"
            exact
            render={(routeProps) => <AsyncCompleteRegistrationPage {...routeProps} />} />

          <Route
            path="/error/unauthorized"
            exact
            component={AsyncUnauthorizedErrorPage} />

          <Route component={AsyncNotFound} />
        </AnimatedSwitch>
      </ScrollToTop>
    </>
  )
}

export default () => {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <PageViewTrackedRoutes />
      </BrowserRouter>
    </ProvideAuth>
  )
};
