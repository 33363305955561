import axios from 'axios';
import authenticationService from './AuthenticationService';

const sellClient = axios.create({
  baseURL: process.env.REACT_APP_SELL_API_URL
});
const searchClient = axios.create({
  baseURL: process.env.REACT_APP_SEARCH_API_URL
});

let summaryCache;

export default {
  createAdSurveyResponse: async (surveyResponse) => {
    let response = await sellClient.post('/ad-survey-response', surveyResponse, await authenticationService.getRequestOptions());

    return response.data;
  },
  getAdSurveyResponseSummary: async () => {
    if (summaryCache) {
      return summaryCache;
    }

    let response = await searchClient.get('/ad-survey-response/summary');

    summaryCache = response.data;

    return response.data;
  },
  getAdSurveyResponses: async (from) => {
    let response = await searchClient.get('/ad-survey-response/' + from);

    return response.data;
  }
}