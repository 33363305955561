import React, { useEffect } from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../../Hooks/use-auth';

const MemberList = styled.ul`
  padding: 0;
  margin: 0;
  display: inline-block;
  float: right;
`

const MemberItem = styled.li`
  display: inline-block;
  list-style: none;
  margin-top: 25px;
  font-size: 110%;
  color: white;
  > a {
    color: white;
  }
  .menu .item {      
    a {
      color: black;
    }
  }
`

const LimitedWidthSpan = styled.span`
  max-width: 300px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
`

const DropdownItemWithLink = styled(Dropdown.Item)`
  &&&& {
    padding: 0 !important;
  }
  a {
    padding: 0.78571429rem 1.14285714rem !important;
    display: block;
  }
`

function MemberMenu() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  // Unfortunately this needs to be within a component in order to use useHistory
  // useHistory will be undefined within our custom useAuth hook
  useEffect(() => {
    auth.registerEventHandlers({
      onCustomOAuthState: (nextLocation) => {
        const requiresCompletion = auth.user.authenticated && auth.user['custom:isCompleted'] !== 'true';

        if (requiresCompletion && location.pathname !== '/complete-registration') {
          // This social sign in hasn't been comlpeted yet - take them to do that
          history.replace('/complete-registration', { nextLocation });
        }
        else {
          // This social sign in is all completed, just take them directly to where they tried to go
          history.replace(nextLocation);
        }
      }
    });
  }, [auth, history, location.pathname]);

  useEffect(() => {

  }, [auth, history, location]);

  return <MemberList>
    {auth.user && auth.user.authenticated
      ? <MemberItem>
        <Dropdown direction="left"
          trigger={
            <LimitedWidthSpan>
              <Icon name='user' /> {auth.user.name}
            </LimitedWidthSpan>}>
          <Dropdown.Menu>
            <Dropdown.Item disabled>
              <p>
                Signed in as {auth.user.name}
              </p>
              <strong>{auth.user.email}</strong>
            </Dropdown.Item>
            <Dropdown.Divider />
            <DropdownItemWithLink>
              <a href={`${process.env.REACT_APP_WEB_URL}member/profile`}>Profile</a>
            </DropdownItemWithLink>
            <DropdownItemWithLink>
              <Link to='/'>Place New Ad</Link>
            </DropdownItemWithLink>
            <DropdownItemWithLink>
              <Link to='/dashboard'>Manage Ad</Link>
            </DropdownItemWithLink>
            <Dropdown.Item onClick={() => auth.signout()}>
              Sign Out
            </Dropdown.Item>
            {auth.user.isAdmin && <>
              <Dropdown.Divider />
              <Dropdown.Header><Icon name='lock' /> Admin</Dropdown.Header>
              <DropdownItemWithLink>
                <Link to='/admin'>Administration</Link>
              </DropdownItemWithLink>
              <DropdownItemWithLink>
                <Link to='/admin-analytics'>Analytics</Link>
              </DropdownItemWithLink>
            </>}
          </Dropdown.Menu>
        </Dropdown>
      </MemberItem>
      : <MemberItem>
        <Link to={{
          pathname: '/authenticate',
          state: {
            from: location
          }
        }}>Sign In / Join</Link>
      </MemberItem>
    }
  </MemberList>
}

export default MemberMenu
