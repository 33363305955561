import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.footer`
  background-color: #1B1B1B;
`
const FooterNav = styled.div`
`

const FooterNavInfo = styled.div`
  max-width: 300px;

  p {
    color: #ccc;
  }
  
`

const FooterNavCollection = styled.div`
  padding: 60px 100px;
  display: flex;
  gap: 100px;

  @media (max-width: 1024px) {
    padding: 60px 50px;
    gap: 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const FooterLower = styled.div`
  padding: 15px 40px;
  color: #ddd;
  text-align: center;
  background-color: #2b2b2b;
  font-size: 90%;
  a {
    color: #eee;
    text-decoration: underline;
  }
  a:hover {
    color: #fff;
  }
`

const Clr = styled.div`
  clear: both;
`

const NavTitle = styled.h3`
  color: white;
  margin-bottom: 4px;
`
const NavList = styled.ul`
  padding: 0;
  margin: 0;
`
const NavItem = styled.li`
  list-style: none;
  padding: 3px 0;
  > a {
    color: #ddd;
  }
  > a:hover {
    color: #fff;
  }
`

const Highlight = styled.span`
  color: #ed1f24;
`

const Line = styled.div`
  height: ${props => props.thickness + 1 || 4}px;
  border-bottom: 2px solid black;
  background-color: ${props => props.color || '#ed1f24'};
`

function Footer(props) {
  return (
    <FooterContainer>
      <Line thickness={6} />
      <Line thickness={4} />
      <Line color='#ff851b' thickness={6} />
      <FooterNavCollection>
        <FooterNav>
          <NavTitle>Motorsport Classifieds</NavTitle>
          <NavList>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/circuit-racing'}>Circuit Racing</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/speedway-oval'}>Speedway & Oval</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/rally-off-road-4wd'}>Rally & Off Road</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/drag-racing-hot-rods'}>Drag Racing & Hot Rods</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/road-drift-performance'}>Road, Drift & Performance Cars</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/classic-prestige'}>Classics & Prestige</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/car-bike-kart/bikes'}>Race Bikes</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/trailer-transporter'}>Trailers, Transporters & Tow Vehicles</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search/parts-accessories-other'}>Parts, Accessories & Other</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'search'}>View All</a>
            </NavItem>
          </NavList>
        </FooterNav>

        <FooterNav>
          <NavTitle>Other Links</NavTitle>
          <NavList>
            <NavItem>
              <a href='/'>Place New Ad</a>
            </NavItem>
            <NavItem>
              <a href='/dashboard'>Manage Ad</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'About-My105'}>Contact Us</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'business/advertising'}>Business Advertising</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'how-auction-works'}>How Auction Works</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'terms'}>Site Terms & Conditions</a>
            </NavItem>
            <NavItem>
              <a href={process.env.REACT_APP_WEB_URL + 'auction-terms'}>Auction Terms & Conditions</a>
            </NavItem>
          </NavList>
        </FooterNav>

        <FooterNavInfo>
          <NavTitle>About <Highlight>my105</Highlight></NavTitle>
          <p>Since 2002, my105.com has been Australia's #1 motorsport and performance classifieds website.</p>
          <p>We're passionate about motorsport in all its forms and are constantly looking for new ways to connect drivers, fans and the businesses that support them.</p>
        </FooterNavInfo>

        <Clr />
      </FooterNavCollection>

      <FooterLower>
        <p>&copy; my105.com 2002-{new Date().getFullYear()} | <a href={process.env.REACT_APP_WEB_URL + 'terms'}>Legal</a>, <a href={process.env.REACT_APP_WEB_URL + 'terms'}>Privacy Policy</a> </p>
      </FooterLower>
    </FooterContainer>
  )
}

export default Footer
