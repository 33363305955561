import React from 'react';
import Routes from './Routes'
import Amplify from 'aws-amplify';
import { datadogLogs } from '@datadog/browser-logs'

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  env: process.env.REACT_APP_DATADOG_CLIENT_ENV,
  forwardConsoleLogs: ['warn', 'error'],
  sampleRate: 100,
});

var amplifyConfig = {
  "aws_project_region": "ap-southeast-2",
  "aws_cognito_region": "ap-southeast-2",
  "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  "oauth": {
    "domain": process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": process.env.REACT_APP_COGNITO_REDIRECT_URL,
    "redirectSignOut": process.env.REACT_APP_COGNITO_REDIRECT_URL,
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  // We're using USER_PASSWORD_AUTH so we can migrate users from the classic site.
  // When this is no longer required we should switch it back to USER_SRP_AUTH
  // which is the default so simply removing this line below will do it
  // Reference:  https://docs.amplify.aws/lib/auth/switch-auth/q/platform/js
  "authenticationFlowType": "USER_PASSWORD_AUTH"
};

Amplify.configure(amplifyConfig);

function App() {
  return (
    <Routes />
  )
}

export default App