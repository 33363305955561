import React from 'react'
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const SellerUSPContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  padding: 10px 20px;
  width: 320px;
  display: flex;
  align-items: center;
  gap: 20px;
`

const SellerUSPIcon = styled.span`
  display: inherit;
  min-width: 40px;
  svg {
    fill: white;
  }
`

const SellerUSPText = styled.div`

`

const SellerUSPHeading = styled.h3`
  margin: 0;
`

const SellerUSPBody = styled.p`

`

function SellerUSP({ heading, body, icon }) {
  return <SellerUSPContainer>
    <SellerUSPIcon>{icon}</SellerUSPIcon>
    <SellerUSPText>
      <SellerUSPHeading>{heading}</SellerUSPHeading>
      <SellerUSPBody>{body}</SellerUSPBody>
    </SellerUSPText>
  </SellerUSPContainer>
}

const Container = styled.div`
  padding-top: 50px;
  color: white;
`

const Body = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const Heading = styled.h2`
  text-align: center;
  margin-bottom: 30px;
`

function SellerUSPs({ thirdHeadingOverride }) {

  return <Container>
    <Heading>Why sell with us?</Heading>
    <Body>
      <SellerUSP
        heading={`Proudly #1`}
        body={`Australia's favourite motorsport and enthusiast marketplace.`}
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="33">
            <path d="M4.1 38.2C1.4 34.2 0 29.4 0 24.6C0 11 11 0 24.6 0H133.9c11.2 0 21.7 5.9 27.4 15.5l68.5 114.1c-48.2 6.1-91.3 28.6-123.4 61.9L4.1 38.2zm503.7 0L405.6 191.5c-32.1-33.3-75.2-55.8-123.4-61.9L350.7 15.5C356.5 5.9 366.9 0 378.1 0H487.4C501 0 512 11 512 24.6c0 4.8-1.4 9.6-4.1 13.6zM80 336a176 176 0 1 1 352 0A176 176 0 1 1 80 336zm184.4-94.9c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z" />
          </svg>
        }
      />
      <SellerUSP
        heading={`Dedicated since 2002`}
        body='Trusted by thousands of buyers and sellers for over 20 years.'
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="33">
            <path d="M323.4 85.2l-96.8 78.4c-16.1 13-19.2 36.4-7 53.1c12.9 17.8 38 21.3 55.3 7.8l99.3-77.2c7-5.4 17-4.2 22.5 2.8s4.2 17-2.8 22.5l-20.9 16.2L550.2 352H592c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48H516h-4-.7l-3.9-2.5L434.8 79c-15.3-9.8-33.2-15-51.4-15c-21.8 0-43 7.5-60 21.2zm22.8 124.4l-51.7 40.2C263 274.4 217.3 268 193.7 235.6c-22.2-30.5-16.6-73.1 12.7-96.8l83.2-67.3c-11.6-4.9-24.1-7.4-36.8-7.4C234 64 215.7 69.6 200 80l-72 48H48c-26.5 0-48 21.5-48 48V304c0 26.5 21.5 48 48 48H156.2l91.4 83.4c19.6 17.9 49.9 16.5 67.8-3.1c5.5-6.1 9.2-13.2 11.1-20.6l17 15.6c19.5 17.9 49.9 16.6 67.8-2.9c4.5-4.9 7.8-10.6 9.9-16.5c19.4 13 45.8 10.3 62.1-7.5c17.9-19.5 16.6-49.9-2.9-67.8l-134.2-123z" />
          </svg>
        }
      />
      <SellerUSP
        heading={thirdHeadingOverride || `List until sold`}
        body='One fair, flat fee. No more to pay and no ongoing payments.'
        icon={
          <Icon name='gavel' size='big' style={{ margin: 'auto' }} />
        }
      />
    </Body>
  </Container >
}

export default SellerUSPs;