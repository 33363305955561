import React from 'react';
import { useAuth } from '../../Hooks/use-auth';

import {
  Route,
  Redirect
} from "react-router-dom";
import AuthenticationService from '../../Services/AuthenticationService';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ allowGuest, children, ...rest }) {
  let auth = useAuth();
  return <Route
    {...rest}
    render={(routeProps) => {
      const isMember = (auth.user && auth.user.authenticated);
      const isGuest = (allowGuest && AuthenticationService.isGuest());
      return (isMember || isGuest)
        ? rest.render(routeProps)
        : <Redirect
          to={{
            pathname: "/authenticate",
            state: { from: routeProps.location }
          }}
        />
    }}
  />
}

export default PrivateRoute;