import React, { Component } from 'react'

export default function ProtectedRoute(importComponent, auth) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props)
      this.state = {
        component: null
      }
    }

    async componentDidMount() {
      try {
        const { default: component } = await importComponent()
        this.setState({
          component: component
        })
      }
      catch (error) {
        if (error.toString().indexOf('ChunkLoadError') > -1) {
          console.error('[ChunkLoadError] Reloading due to error.  Make this a log message rather than error after we have observed it working well enough');
          window.location.reload();
        }
        else {
          console.error('failed to load component', error);
          throw error;
        }
      }
    }

    render() {
      const C = this.state.component

      return C ? <C {...this.props} /> : null
    }
  }

  return AsyncComponent
}
