import React, { useEffect } from 'react';
import Page from '../../Components/Furniture/Page';
import CategoryCards from '../../Components/Create/CategoryCards';
import styled from 'styled-components';
import analyticService from '../../Services/AnalyticService';
import SellerRatingsWidget from '../../Components/Create/SellerRatingsWidget';
import SellerUSPs from '../../Components/Create/SellerUSPs';
import RatingStars from '../../Components/Create/RatingStars';

const isSnapshotting = navigator.userAgent === 'ReactSnap';

const Background = styled.div`
  background-color: black;
  @media only screen and (min-width: 768px) {
    background: url(/landing-bg.jpg);
    background-repeat: no-repeat;
    background-position-x: center;
  }
`;

function LandingPage() {
  useEffect(() => {
    analyticService.sendViewItemListEvent();
  }, []);

  return (
    <Background>
      <Page
        contentStyle={{
          background:
            'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/landing-bg.jpg)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
        }}
        footer={!isSnapshotting && <SellerRatingsWidget />}
      >
        <h1
          style={{
            textAlign: 'center',
            margin: '0 0 50px 0',
            fontSize: '42px',
          }}
        >
          What are you selling today?
        </h1>
        <CategoryCards />

        <div
          style={{
            maxWidth: '500px',
            margin: '50px auto 0 auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            alignItems: 'center',
          }}
        >
          <RatingStars rating={5} size="big" color="red" />
          <div
            style={{
              display: 'flex',
              gap: '10px',
              fontStyle: 'italic',
              fontSize: '18px',
              lineHeight: '1.5',
              textAlign: 'center',
            }}
          >
            Sold in 30 mins. Thank you MY105. If you are looking to sell or buy
            something special this is the site for you.
          </div>
          <div>
            <strong>Ben Finnis</strong> | member since 2012
          </div>
        </div>

        <SellerUSPs />
      </Page>
    </Background>
  );
}

export default LandingPage;
