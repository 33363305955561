
import { Auth } from 'aws-amplify';

const getCurrentUserAttributes = async () => {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().payload;
  }
  catch (error) {
    return {};
  }
}

const GUEST_DATA_KEY = 'My105Guest';

let GuestInstance;

let onClearGuestCallback;

const self = {
  isAdmin: async () => {
    const userAttributes = await getCurrentUserAttributes();
    return userAttributes['custom:isAdmin'] === 'true';
  },
  getRequestOptions: async (authenticationToken, allowGuest, allowAnonymous) => {
    let guest = allowGuest ? self.getGuest() : null;
    try {
      authenticationToken = authenticationToken || (await Auth.currentSession()).idToken.jwtToken;
    }
    catch (ex) {
      if (ex === 'No current user' && guest) {
        // This is okay, because we expected a guest
      }
      else if (allowAnonymous) {
        // This is okay because we expected anonymous
      }
      else {
        console.error('failed to get authenticationToken', ex);
      }
    }
    const result = {
      headers: {
        Authorization: authenticationToken
      }
    };
    if (guest) {
      result.headers.GuestAuthorization = guest.token;
    }
    return result;
  },
  getUserSub: async () => {
    const userAttributes = await getCurrentUserAttributes();
    return userAttributes.sub;
  },
  setGuest: (guest) => {
    try {
      GuestInstance = guest;
      localStorage.setItem(GUEST_DATA_KEY, JSON.stringify(guest));
    }
    catch (ex) {
      console.error('failed to store guest in local storage', ex);
    }
  },
  getMemberAuthorization: async () => {
    try {
      return (await Auth.currentSession()).idToken.jwtToken;
    }
    catch (ex) {
      return null;
    }
  },
  isMember: async () => {
    return !!(await self.getMemberAuthorization());
  },
  isGuest: () => {
    return !!self.getGuest();
  },
  isMemberOrGuest: async () => {
    return !!(await self.isMember() || self.isGuest());
  },
  clearGuest: () => {
    try {
      GuestInstance = null;
      localStorage.removeItem(GUEST_DATA_KEY);
      if (onClearGuestCallback) {
        onClearGuestCallback();
      }
    }
    catch (ex) {
      console.error('failed to clear guest in local storage', ex);
    }
  },
  getGuest: () => {
    if (!GuestInstance) {
      try {
        const guestData = localStorage.getItem(GUEST_DATA_KEY);
        if (guestData) {
          GuestInstance = JSON.parse(guestData);
        }
      }
      catch (ex) {
        console.error('failed to parse guest in local storage', ex);
      }
    }

    return GuestInstance;
  },
  getUser: async () => {
    return await getCurrentUserAttributes();
  },
  registerOnClearGuest: callback => onClearGuestCallback = callback
};

export default self;