import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { useAuth } from '../../Hooks/use-auth';

const MobileView = styled.span`
  @media only screen and (min-width: 1023px) {
    display: none;
  }
  margin-left: auto;
`

const MenuContainer = styled.nav`
  display: ${props => props.open ? 'block' : 'none'};
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1B1B1B;
  z-index: 1000;
`

const MenuHeader = styled.div`
  padding: 10px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuList = styled.ul`
  margin: 0;
  padding: 0;
`

const MenuItem = styled.li`
  list-style: none;
  a, .menu-link {
    display: block;
    color: white;
    padding: 20px 30px;
    border-top: 1px solid #555;
    font-size: 130%;
    cursor: pointer;

    &:hover {
      background-color: #2b2b2b;
    }
  }
`

const LogoImage = styled.img`
  width: 170px;
`
const CloseIcon = styled.span`
  display: inline-block;
  cursor: pointer;
  float: right;
  font-size: 20px;
  color: white;
  margin-right: 5px;
`

function MobileHamburger() {
  const [menuOpen, setMenuOpen] = useState(false)
  const auth = useAuth();

  const closeOnClick = () => setMenuOpen(false)

  const signOut = async () => {
    await auth.signout();
    setMenuOpen(false);
  }

  return (
    <MobileView>
      <Icon size='large' color='red' name='bars' onClick={() => setMenuOpen(true)} />
      <MenuContainer open={menuOpen}>
        <MenuHeader>

          <a href={process.env.REACT_APP_WEB_URL}>
            <LogoImage src='/logo-without-tagline.png' />
          </a>
          <CloseIcon onClick={closeOnClick}>&#9587;</CloseIcon>
        </MenuHeader>
        <MenuList>
          {auth.user && auth.user.authenticated
            ?
            <>
              <MenuItem>
                <a href="tel:+61280069105"> Get in Touch:  (02) 800 69 105 </a>
              </MenuItem>
              <MenuItem onClick={closeOnClick}>
                <a href={`${process.env.REACT_APP_WEB_URL}member/profile`}>Profile</a>
              </MenuItem>
              <MenuItem onClick={closeOnClick}>
                <Link to='/'>Place New Ad</Link>
              </MenuItem>
              <MenuItem onClick={closeOnClick}>
                <Link to='/dashboard'>Manage Ad</Link>
              </MenuItem>
              <MenuItem onClick={signOut}>
                <span className='menu-link'>Sign Out</span>
              </MenuItem>
            </>
            :
            <>
              <MenuItem>
                <a href="tel:+61280069105"> Get in Touch:  (02) 800 69 105 </a>
              </MenuItem>
              <MenuItem>
                <Link to='/' onClick={closeOnClick}>Place New Ad</Link>
              </MenuItem>
              <MenuItem>
                <Link to='/dashboard' onClick={closeOnClick}>Manage Ad</Link>
              </MenuItem>
              <MenuItem onClick={closeOnClick}>
                <Link to='/authenticate'>Sign In / Join</Link>
              </MenuItem>
            </>
          }
        </MenuList>
      </MenuContainer>
    </MobileView>
  )
}

export default MobileHamburger
