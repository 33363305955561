import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import MobileHamburger from './MobileHamburger'
import MemberMenu from './MemberMenu'

const HeaderContainer = styled.header`
  background-color: #1B1B1B;
  padding: 0 20px;
  height: 80px;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #3b3b3b;

  @media only screen and (max-width: 599px) {
    height: auto;
    padding: 10px 20px;
  }
`

const LogoLink = styled.a`
  display: inline-block;
  float: left;
`

const HeaderLogo = styled.img`
  width: 250px;

  @media only screen and (max-width: 599px) {
    width: 170px;
  }
`

const NavList = styled.ul`
  padding: 0;
  margin: 0;
  display: inline-block;
  margin-left: 50px;
  float: left;
`

const NavItem = styled.li`
  display: inline-block;
  list-style: none;
  padding-right: 40px;
  margin-top: 25px;
  font-size: 110%;
  > a {
    color: white;
  }
`

const BrowserView = styled.span`
  flex: 1;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`
const Clr = styled.div`
  clear: both;
`

const ContactSpan = styled.span`
  color: white;
  display: inline-block;
  list-style: none;
  padding-right: 40px;
  margin-top: 25px;
  font-size: 110%;
  float: right;
  margin-right: 100px;
  > a {
    color: white;
  }  
  @media only screen and (max-width: 1199px) {
    margin-right: 10px;
    padding-right: 10px;
  }
`

const SpanStartContactNumber = styled.span`
  font-weight: bold;
  > a {
    color: white;
  }
`

const SpanEndContactNumber = styled.span`
  float: right;
  color: #ccc
`

function Header(props) {
  return (
    <HeaderContainer>
      <LogoLink href={process.env.REACT_APP_WEB_URL}>
        <HeaderLogo src='/logo-without-tagline.png' title='my105 - No. 1 Race car classfieds' />
      </LogoLink>

      <BrowserView>
        <NavList>
          <NavItem>
            <Link to='/'>Place New Ad</Link>
          </NavItem>
          <NavItem>
            <Link to='/dashboard'>Manage Ad</Link>
          </NavItem>
        </NavList>
        <MemberMenu />
        <ContactSpan>
          Get in Touch:  <SpanStartContactNumber> <a href="tel:+61280069105">(02) 800 MY105 </a> </SpanStartContactNumber> <br /> <SpanEndContactNumber> 69105 </SpanEndContactNumber>
        </ContactSpan>
      </BrowserView>

      <MobileHamburger />

      <Clr />
    </HeaderContainer>
  )
}

export default Header
