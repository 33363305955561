import axios from 'axios';
import authenticationService from './AuthenticationService';

const client = axios.create({
  baseURL: process.env.REACT_APP_SELL_API_URL
});

let claimGuestIntervalId = null;

export default {
  getOrCreateGuest: async () => {
    try {
      let guest = authenticationService.getGuest();

      if (!guest) {
        const response = await client.post('/guest');
        guest = response.data;
        authenticationService.setGuest(guest);
      }

      return guest;
    }
    catch (ex) {
      console.error('failed to create guest', ex);
    }
  },
  tryClaimGuest: async () => {
    const executeTryClaimGuest = async (onError) => {
      try {
        if (authenticationService.isGuest()) {
          await client.post('/guest/claim', {}, await authenticationService.getRequestOptions(null, true));
          authenticationService.clearGuest();
        }
        if (claimGuestIntervalId) {
          clearInterval(claimGuestIntervalId);
        }
      }
      catch (ex) {
        console.error('failed to claim guest', ex);
        if (onError) {
          onError();
        }
      }
    }

    await executeTryClaimGuest(() => {
      claimGuestIntervalId = setInterval(executeTryClaimGuest, 10000);
    });
  },
  create: async (userId, email, issubscribed, authenticationToken = null) => {
    const response = await client.post('/users/event', {
      username: userId,
      email: email,
      issubscribed: issubscribed
    }, await authenticationService.getRequestOptions(authenticationToken));
    return response.data;
  },
  getStatus: async (userId) => {
    const response = await client.get(`/users/${userId}/status`, await authenticationService.getRequestOptions());
    return response.data;
  },
  getSubscription: async (userId) => {
    const response = await client.get(`/users/${userId}/subscription`, await authenticationService.getRequestOptions());
    return response.data;
  },
  changePlan: async (userId, planId) => {
    const response = await client.post(`/users/${userId}/plans/${planId}`, {}, await authenticationService.getRequestOptions());
    return response.data;
  },
  mergeAccounts: async (email) => {
    const response = await client.post(`/users/merge`, { email }, await authenticationService.getRequestOptions());
    return response.data;
  }
}